<template>
    <v-card flat>
        <v-card-text>
            <div class="d-flex justify-end">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">Actions</v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="newTransactionDialog = true">
                            <v-list-item-title class="subtitle-2 secondary--text">New Transaction</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="mapRemoveDialog = true">
                            <v-list-item-title class="subtitle-2 secondary--text">Map/Remove Resource</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="newResourcePoolDialog = true">
                            <v-list-item-title class="subtitle-2 secondary--text">New Resource Pool</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-dialog :persistent="creatingTransaction" v-model="newTransactionDialog" max-width="500">
                <v-card>
                    <v-card-title>
                        <div class="d-flex align-center secondary--text">
                            <v-icon class="mr-1">add</v-icon>
                            New transaction
                        </div>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                        <v-form ref="newTransactionForm" v-model="validTransaction">
                            <v-select
                                :items="availableResourcePools"
                                :disabled="creatingTransaction"
                                item-text="long_id"
                                item-value="rpid"
                                dense
                                label="Resource pool"
                                v-model="selectedResourcePoolForTransaction"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-select
                                v-model="transactionResourceType"
                                :disabled="creatingTransaction"
                                :items="resourceTypes"
                                item-text="label"
                                item-value="value"
                                label="Resource type"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-select
                                v-model="transactionType"
                                :disabled="creatingTransaction"
                                item-text="type"
                                item-value="code"
                                :items="newTransactionTypes"
                                label="Transaction type"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-text-field
                                :disabled="creatingTransaction"
                                v-model="transactionAmount"
                                label="Amount"
                                :rules="transactionAmountValidationRules"
                                required
                                outlined></v-text-field>
                            <v-textarea :disabled="creatingTransaction" rows="2" v-model="transactionComment" label="Comment" outlined></v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!validTransaction" @click="createTransaction" :loading="creatingTransaction" color="error" text>Confirm</v-btn>
                        <v-btn @click="clearNewTransactionForm" :disabled="creatingTransaction" color="secondary" text>Clear</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog :persistent="performingOperation" v-model="mapRemoveDialog" max-width="500">
                <v-card>
                    <v-card-title>
                        <div class="d-flex align-center secondary--text">
                            <v-icon class="mr-1">add</v-icon>
                            Map/Remove Resource
                        </div>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                        <v-form ref="form" v-model="validOperation">
                            <v-select
                                :items="availableResourcePools"
                                :disabled="performingOperation"
                                item-text="long_id"
                                item-value="rpid"
                                dense
                                label="Resource pool"
                                v-model="selectedResourcePoolForMapDelete"
                                outlined></v-select>
                            <v-select
                                :items="Object.values(relationActionTypes)"
                                :disabled="performingOperation"
                                label="Action"
                                v-model="selectedOperation"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-select
                                :items="Object.values(relationActionLevel)"
                                :disabled="performingOperation"
                                label="Object type"
                                v-model="selectedOperationLevel"
                                v-if="selectedOperation"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-text-field
                                v-if="selectedOperationLevel"
                                :label="
                                    selectedOperationLevel === relationActionLevel.ORGANIZATION ? 'Nuvolos organization identifier' : 'Nuvolos space identifier'
                                "
                                type="number"
                                v-model="nuvolosIdentifier"
                                :rules="[rules.required, rules.isInteger]"
                                required
                                outlined></v-text-field>
                            <v-select
                                v-if="selectedOperationLevel === relationActionLevel.SPACE"
                                v-model="selectedResourceType"
                                :items="resourceTypes"
                                item-text="label"
                                item-value="value"
                                label="Resource type"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!validOperation" @click="performSelectedOperation" color="primary">Confirm Operation</v-btn>
                        <v-btn @click="clearResourceMappingForm" text>Clear</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog :persistent="creatingNewResourcePool" v-model="newResourcePoolDialog" max-width="800">
                <v-card>
                    <v-card-title>
                        <div class="d-flex align-center secondary--text">
                            <v-icon class="mr-1">add</v-icon>
                            New resource pool
                        </div>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-divider></v-divider>
                    <v-card-text class="mt-5">
                        <v-form ref="newContractForm" v-model="validContract">
                            <v-select
                                :items="contractTypes"
                                :disabled="creatingNewResourcePool"
                                item-text="type"
                                item-value="code"
                                dense
                                label="Contract type"
                                v-model="newContractType"
                                :rules="[rules.required]"
                                required
                                outlined></v-select>
                            <v-text-field
                                :disabled="creatingNewResourcePool"
                                v-model="newContractName"
                                label="Contract name"
                                :rules="[rules.required]"
                                required
                                outlined></v-text-field>

                            <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractDateRangeText"
                                        label="Contract duration"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[rules.required, rules.rangeRequired, rules.dateOrder]"
                                        required
                                        outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="newContractDurationRange" range></v-date-picker>
                            </v-menu>
                            <v-text-field
                                :disabled="creatingNewResourcePool"
                                v-model="newContractInitialCredit"
                                label="Initial credit balance"
                                :rules="[rules.required, rules.isFloat]"
                                required
                                outlined></v-text-field>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractBurstCapacity"
                                        label="Burst capacity"
                                        :rules="[rules.required, rules.isInteger]"
                                        required
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractBaseCapacity"
                                        label="Base capacity"
                                        :rules="[rules.required, rules.isInteger]"
                                        required
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractRoundTheClockCapacity"
                                        label="Round-the-clock capacity"
                                        :rules="[rules.required, rules.isInteger]"
                                        required
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractSalesforceName"
                                        :rules="[rules.salesforceNameValidation]"
                                        @input="validateSalesforce"
                                        label="Salesforce name"
                                        outlined></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :disabled="creatingNewResourcePool"
                                        v-model="newContractSalesforceUrl"
                                        :rules="[rules.salesforceUrlValidation]"
                                        @input="validateSalesforce"
                                        label="Salesforce url"
                                        outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-checkbox :disabled="creatingNewResourcePool" label="Add Data support" v-model="newContractHasData"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox
                                        :disabled="creatingNewResourcePool"
                                        label="Add credit-based sizes support"
                                        v-model="newContractHasHPC"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox
                                        :disabled="creatingNewResourcePool"
                                        label="Add Video Library support"
                                        v-model="newContractHasVimeo"></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2">
                                    <v-checkbox
                                        :disabled="creatingNewResourcePool"
                                        label="Create new organization"
                                        v-model="newContractAddOrganization"></v-checkbox>
                                </v-col>
                                <v-col v-if="newContractAddOrganization" cols="5">
                                    <v-text-field
                                        v-model="newContractOrgLongName"
                                        label="Organization name"
                                        :disabled="creatingNewResourcePool"
                                        :rules="[rules.required]"
                                        required
                                        outlined></v-text-field>
                                    <ShortIdInput
                                        :disabledEdit="creatingNewResourcePool"
                                        :longId="newContractOrgLongName"
                                        @input="newContractOrgShortName = $event.nextValue"></ShortIdInput>
                                </v-col>
                                <v-col v-if="newContractAddOrganization" cols="5">
                                    <v-textarea
                                        v-model="newContractOrgDescription"
                                        label="Organization description"
                                        rows="1"
                                        auto-grow
                                        :disabled="creatingNewResourcePool"
                                        :rules="[rules.required]"
                                        required
                                        outlined></v-textarea>
                                </v-col>
                            </v-row>
                            <v-textarea
                                :disabled="creatingNewResourcePool"
                                rows="5"
                                v-model="newContractComment"
                                label="Contract comment"
                                outlined></v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :loading="creatingNewResourcePool" @click="createNewContract" color="primary" text>Confirm</v-btn>
                        <v-btn :disabled="creatingNewResourcePool" @click="clearContractForm" color="secondary" text>Clear</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-row>
                <v-col md="4" sm="6" cols="12">
                    <v-select
                        v-model="transactionsListResourceType"
                        :disabled="fetchingTransactions"
                        :loading="fetchingTransactions"
                        :items="resourceTypes"
                        item-text="label"
                        dense
                        item-value="value"
                        label="Resource type"
                        @change="fetchTransactions"
                        outlined></v-select>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                    <v-select
                        v-model="transactionsListTransactionType"
                        :disabled="fetchingTransactions"
                        :loading="fetchingTransactions"
                        item-text="type"
                        dense
                        item-value="code"
                        :items="transactionTypes"
                        label="Transaction type"
                        @change="fetchTransactions"
                        outlined></v-select>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                    <div class="d-flex flex-column justify-end">
                        <v-select
                            :items="dateRangeOptions"
                            dense
                            label="Date range"
                            v-model="selectedDateRangeOption"
                            @change="updateDateRange"
                            :rules="[rules.required]"
                            required
                            outlined></v-select>
                        <v-menu
                            v-if="selectedDateRangeOption === 'Custom range'"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="250px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    ref="customDatePicker"
                                    dense
                                    :disabled="fetchingTransactions"
                                    v-model="dateRangeText"
                                    label="Range"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required, rules.rangeRequired, rules.dateOrder]"
                                    required
                                    outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="dataDateRange" :max="new Date().toISOString().substr(0, 10)" range></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>
            <v-card flat>
                <v-card-title>
                    <div class="d-flex align-center w-100">
                        Transactions history
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="transactionSearch"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            outlined
                            dense></v-text-field>
                    </div>
                </v-card-title>
                <v-card-text>
                    <v-data-table :loading="fetchingTransactions" :headers="transactionsTableHeaders" :items="transactionsHistory" :search="transactionSearch">
                        <template v-slot:[`item.transaction_timestamp`]="{ item }">
                            {{ item.transaction_timestamp | dateToHuman }}
                        </template>
                        <template v-slot:[`item.transaction_type`]="{ item }">
                            {{ getTransactionType(item.transaction_type) }}
                        </template>
                        <template v-slot:[`item.resource_type`]="{ item }">
                            {{ getResourceTypeByCode(item.resource_type) }}
                        </template>
                        <template v-slot:[`item.transaction_amount`]="{ item }">
                            {{ roundTransactionAmount(item.transaction_amount) }}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import { usageAndResources } from '@/mixins/usageAndResources'
import { mapState } from 'vuex'
import { round } from 'lodash'
import ShortIdInput from '@/components/ShortIdInput'

const contract = {
    DurationRange: [],
    Comment: '',
    OrgShortName: '',
    OrgLongName: '',
    OrgDescription: '',
    HasData: false,
    HasHPC: false,
    HasVimeo: false,
    AddOrganization: false
}

export default {
    mixins: [usageAndResources],
    components: { ShortIdInput },
    props: { tab: Number },
    data() {
        return {
            dialog: false,
            creatingTransaction: false,
            creatingNewResourcePool: false,
            transactionAmount: '',
            newTransactionDialog: false,
            newResourcePoolDialog: false,
            transactionResourceType: null,
            transactionType: null,
            transactionComment: '',
            validTransaction: false,
            validOperation: false,
            selectedOperation: null,
            selectedResourceType: null,
            transactionsListResourceType: null,
            transactionsListTransactionType: null,
            newContractInitialCredit: null,
            newContractBurstCapacity: null,
            newContractBaseCapacity: null,
            newContractType: null,
            newContractRoundTheClockCapacity: null,
            newContractSalesforceName: '',
            newContractSalesforceUrl: '',
            newContractDurationRange: contract.DurationRange,
            newContractHasData: contract.HasData,
            newContractHasHPC: contract.HasHPC,
            newContractHasVimeo: contract.HasVimeo,
            newContractAddOrganization: contract.AddOrganization,
            newContractName: null,
            newContractOrgLongName: contract.OrgLongName,
            newContractOrgShortName: contract.OrgShortName,
            newContractOrgDescription: contract.OrgDescription,
            newContractComment: contract.Comment,
            mapRemoveDialog: false,
            selectedResourcePoolForTransaction: null,
            nuvolosIdentifier: null,
            selectedOperationLevel: null,
            selectedResourcePoolForMapDelete: null,
            transactionsHistory: [],
            fetchingTransactions: false,
            transactionSearch: '',
            transactionTypes: [
                { type: 'All', code: null },
                { type: 'Add', code: 0 },
                { type: 'Deduct', code: 10 },
                { type: 'Initial', code: 30 },
                { type: 'Internal add', code: 40 },
                { type: 'Internal deduct', code: 50 }
            ],
            transactionsTableHeaders: [
                { text: 'Transaction time', value: 'transaction_timestamp' },
                { text: 'Type', value: 'transaction_type' },
                { text: 'Resource type', value: 'resource_type' },
                { text: 'Comment', value: 'transaction_comment' },
                { text: 'Amount (AC)', value: 'transaction_amount' }
            ],
            validContract: false,
            rules: {
                salesforceNameValidation: true,
                salesforceUrlValidation: true
            }
        }
    },
    computed: {
        ...mapState('userStore', ['fetchingAvailableResourcePools', 'availableResourcePools', 'selectedResourcePool', 'fetchingResourcePoolResources']),
        dateRangeText() {
            if (this.dataDateRange) {
                return this.dataDateRange.join(' to ')
            }
            return ''
        },
        newTransactionTypes() {
            return this.transactionTypes.filter(transaction => transaction.type !== 'All')
        },
        newContractDateRangeText: {
            get() {
                if (this.newContractDurationRange.length) {
                    return this.newContractDurationRange.join(' to ')
                }
                return ''
            },
            set(val) {
                this.newContractDurationRange = []
            }
        },
        transactionAmountValidationRules() {
            const rules = [this.rules.isFloat]
            rules.push(this.transactionType === 10 ? this.rules.isNegative : this.rules.isPositive)
            return rules
        }
    },
    methods: {
        validateSalesforce() {
            const name = this.newContractSalesforceName
            const url = this.newContractSalesforceUrl
            if ((name && url) || (!name && !url)) {
                this.rules.salesforceNameValidation = true
                this.rules.salesforceUrlValidation = true
            } else if (name && !url) {
                this.rules.salesforceNameValidation = true
                this.rules.salesforceUrlValidation = 'Salesforce url can not be empty because Salesforce name is present.'
            } else if (!name && url) {
                this.rules.salesforceUrlValidation = true
                this.rules.salesforceNameValidation = 'Salesforce name can not be empty because Salesforce url is present.'
            }
        },
        roundTransactionAmount(num) {
            return round(num, 2)
        },
        getTransactionType(transactionCode) {
            return this.transactionTypes.find(type => type.code === transactionCode).type
        },
        setInitialResourcePool() {
            if (this.availableResourcePools) {
                this.selectedResourcePoolForTransaction = this.availableResourcePools[0].rpid
            }
        },
        performSelectedOperation() {
            if (this.selectedOperation === this.relationActionTypes.MAP) {
                this.mapResourcePool(
                    this.selectedResourcePoolForMapDelete,
                    Number(this.nuvolosIdentifier),
                    this.selectedOperationLevel,
                    this.selectedResourceType
                )
            } else {
                this.deleteResourceMapping(
                    this.selectedResourcePoolForMapDelete,
                    Number(this.nuvolosIdentifier),
                    this.selectedOperationLevel,
                    this.selectedResourceType
                )
            }
        },
        clearContractForm() {
            this.$refs.newContractForm.reset()
            // settign back defaults
            this.newContractDurationRange = contract.DurationRange
            this.newContractHasData = contract.HasData
            this.newContractHasHPC = contract.HasHPC
            this.newContractHasVimeo = contract.HasVimeo
            this.newContractAddOrganization = contract.AddOrganization
            this.newContractOrgLongName = contract.OrgLongName
            this.newContractOrgShortName = contract.OrgShortName
            this.newContractOrgDescription = contract.OrgDescription
            this.newContractComment = contract.Comment
            this.$refs.newContractForm.resetValidation()
        },
        clearResourceMappingForm() {
            this.$refs.resourceMappingForm.reset()
            this.$refs.resourceMappingForm.resetValidation()
        },
        clearNewTransactionForm() {
            if (!this.$refs.newTransactionForm) return
            this.$refs.newTransactionForm.reset()
            this.$refs.newTransactionForm.resetValidation()
        },
        createNewContract() {
            if (!this.$refs.newContractForm.validate()) return
            this.creatingNewResourcePool = true
            const postBody = {
                contract_long_id: this.newContractName,
                contract_type: this.newContractType,
                contract_start: this.newContractDurationRange[0],
                contract_end: this.newContractDurationRange[1],
                initial_credit: this.newContractInitialCredit,
                capacity_rtc: this.newContractRoundTheClockCapacity,
                capacity_base: this.newContractBaseCapacity,
                capacity_burst: this.newContractBurstCapacity,
                contract_description: this.newContractComment,
                org_short_id: this.newContractOrgShortName,
                org_long_id: this.newContractOrgLongName,
                org_description: this.newContractOrgDescription,
                has_database: this.newContractHasData,
                has_hpc: this.newContractHasHPC,
                has_vimeo: this.newContractHasVimeo,
                salesforce_long_id: this.newContractSalesforceName,
                salesforce_uri: this.newContractSalesforceUrl
            }
            this.$axios
                .post('/contract', postBody)
                .then(() => {
                    this.clearContractForm()
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'New resource pool successfully created!.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to create the resource pool.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                    this.newResourcePoolDialog = false
                })
                .finally(() => {
                    this.creatingNewResourcePool = false
                })
        },
        fetchTransactions() {
            this.fetchingTransactions = true
            const postBody = {
                date_start: this.dataDateRange[0],
                date_end: this.dataDateRange[1]
            }
            if (this.transactionsListResourceType !== null) {
                postBody.resource_type = this.transactionsListResourceType
            }
            if (this.transactionsListTransactionType !== null) {
                postBody.transaction_type = this.transactionsListTransactionType
            }
            this.$axios
                .post(`/resource/${this.selectedResourcePool}/transactions/list`, postBody)
                .then(response => {
                    this.transactionsHistory = response.data
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch transaction history.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.fetchingTransactions = false
                })
        },
        createTransaction() {
            this.creatingTransaction = true
            const postBody = {
                resource_type: this.transactionResourceType,
                transaction_type: this.transactionType,
                transaction_amount: parseFloat(this.transactionAmount)
            }
            if (this.transactionAmount) {
                postBody.transaction_comment = this.transactionComment
            }
            this.$axios
                .post(`/resource/${this.selectedResourcePoolForTransaction}/transaction`, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Transaction created successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.clearNewTransactionForm()
                    this.newTransactionDialog = false
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to create the transaction.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.creatingTransaction = false
                    this.fetchTransactions()
                })
        }
    },
    watch: {
        dataDateRange: async function (nextVal) {
            if (!this.selectedResourcePool) return
            // let's wait for the textfield
            await this.$nextTick()
            if (this.$refs.customDatePicker) {
                if (this.$refs.customDatePicker.valid) {
                    this.fetchTransactions()
                }
            } else {
                this.fetchTransactions()
            }
        },
        selectedResourcePool() {
            if (!this.dataDateRange) return
            this.fetchTransactions()
        }
    },
    mounted() {
        this.updateDateRange()
        this.setDateRanges()
    }
}
</script>
